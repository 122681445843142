<template>
    <v-form ref="form" v-model="isValid" @submit.prevent="formSubmitHandler">
        <div class="">
            <!--
            <div class="uppercase text-sm text-grey-2 font-semibold">
                Start for free
            </div>-->
            <h1 class="mt-2 mb-3 text-3xl text-white">Create new account</h1>
            <div class="font-display text-grey-2">
                Already have an account?
                <router-link :to="{ name: 'Login' }">
                    <span class="text-white hover:text-primary">Login.</span>
                </router-link>
            </div>
        </div>

        <div class="mt-7 grid grid-cols-2 gap-x-4">
            <div class="col-span-1">
                <v-text-field
                    v-model="firstName"
                    label="First name"
                    :rules="rules"
                    filled
                    dense
                ></v-text-field>
            </div>
            <div class="col-span-1">
                <v-text-field
                    v-model="lastName"
                    label="Last name"
                    :rules="rules"
                    filled
                    dense
                ></v-text-field>
            </div>
            <!---
            <div class="col-span-1">
                <v-text-field
                    v-model="practiceName"
                    label="Practice name"
                    :rules="rules"
                    filled
                    dense
                ></v-text-field>
            </div>
            <div class="col-span-1">
                <v-select
                    :items="practiceTypes"
                    v-model="practiceType"
                    label="Practice Type"
                    :rules="rules"
                    filled
                    dense
                ></v-select>
            </div>
            -->
            <div class="col-span-1">
                <v-text-field
                    v-model="email"
                    label="Email"
                    type="email"
                    :rules="emailRules"
                    filled
                    dense
                ></v-text-field>
            </div>
            <div class="col-span-1">
                <v-text-field
                    v-model="password"
                    label="Password"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="passwordRules"
                    filled
                    dense
                >
                    <template v-slot:append>
                        <fa-icon
                            :icon="showPassword ? 'eye' : 'eye-slash'"
                            class="mt-1 text-white cursor-pointer"
                            @click="showPasswordHandler"
                        />
                    </template>
                </v-text-field>
            </div>
            <div class="col-span-2">
                <h4 class="">Select a plan</h4>
                <SubscriptionOptions />
            </div>
            <div class="col-span-2 my-4">
                <h4 class="">Payment details</h4>
            </div>
            <div v-if="price && subscriptionOption" class="col-span-2 my-4">
                <StripePayment :price="price" />
            </div>
            <div class="col-span-2">
                <v-alert
                    v-if="showAlert"
                    :type="type"
                    class="text-sm mx-5 mt-8 mb-0"
                    >{{ alertMessage }}</v-alert
                >
            </div>

            <div class="col-span-2">
                <v-checkbox
                    v-model="subscribeToNewsletter"
                    hide-details="true"
                    color="white"
                    :ripple="false"
                    :rules="rules"
                >
                    <template slot="label">
                        <span class="text-grey-3"
                            >I accept the
                            <a href="#" class="text-white"
                                >Privacy Policy</a
                            ></span
                        >
                    </template>
                </v-checkbox>
            </div>
            <div class="col-span-2">
                <v-checkbox
                    v-model="acceptedTermsAndConditions"
                    hide-details="true"
                    color="white"
                    :ripple="false"
                    :rules="rules"
                >
                    <template slot="label">
                        <span class="text-grey-3"
                            >I accept the
                            <a href="#" class="text-white"
                                >Terms & Conditions of Use</a
                            ></span
                        >
                    </template>
                </v-checkbox>
            </div>
            <div class="col-span-2">
                <v-alert
                    v-if="showAlert"
                    :type="type"
                    class="text-sm mx-5 mt-8 mb-0"
                    >{{ alertMessage }}</v-alert
                >
            </div>
            <div class="col-span-1">
                <v-btn
                    :disabled="creatingAccount"
                    class="mt-6 px-12 text-white w-auto"
                    type="submit"
                    depressed
                    color="primary"
                    raised
                    large
                >
                    <fa-icon
                        v-if="creatingAccount"
                        icon="spinner"
                        spin
                        class="mr-2 text-white"
                    />
                    {{ btnText }}
                </v-btn>
            </div>
        </div>
    </v-form>
</template>

<script>
import { computed, ref, watch } from "@vue/composition-api";
import {
    required,
    emailValidator,
    passwordValidator,
} from "@/modules/formRules";
import { PRACTICE_TYPES } from "@/common/constants";
import StripePayment from "@/components/stripe/StripePayment.vue";
import SubscriptionOptions from "@/components/subscription/SubscriptionOptions.vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";

const { useGetters } = createNamespacedHelpers("");

export default {
    name: "SignupForm",
    components: { StripePayment, SubscriptionOptions },
    setup(_, { refs }) {
        const email = ref("");
        const firstName = ref("");
        const lastName = ref("");
        const practiceName = ref("");
        const practiceType = ref("");
        const password = ref("");
        const price = ref(null);
        const subscriptionOption = ref(null);
        const subscribeToNewsletter = ref(false);
        const acceptedTermsAndConditions = ref(false);
        const isValid = ref(true);
        const rules = ref([required]);
        const emailRules = ref([required, emailValidator]);
        const passwordRules = ref([required, passwordValidator]);
        const alertMessage = ref("");
        const showAlert = ref(false);
        const type = ref("");
        const practiceTypes = ref([...PRACTICE_TYPES]);
        const creatingAccount = ref(false);
        const showPassword = ref(false);
        const { newSubscription } = useGetters(["newSubscription"]);

        const btnText = computed(() =>
            creatingAccount.value ? "Saving..." : "Create Account"
        );

        const validate = () => {
            isValid.value = refs.form.validate();
            return isValid.value;
        };

        const showPasswordHandler = () => {
            showPassword.value = !showPassword.value;
        };

        const priceHasChanged = ({ price: newPrice, option }) => {
            price.value = newPrice;
            subscriptionOption.value = option;
        };

        const formSubmitHandler = () => {
            creatingAccount.value = true;
            showAlert.value = false;
            validate();
            if (!isValid.value) {
                creatingAccount.value = false;
                return;
            }
            setTimeout(() => {
                creatingAccount.value = false;
            }, 2000);
        };

        watch(
            newSubscription,
            (newValue) => {
                priceHasChanged(newValue || {});
            },
            { deep: true }
        );

        return {
            email,
            firstName,
            lastName,
            practiceName,
            practiceType,
            password,
            subscribeToNewsletter,
            acceptedTermsAndConditions,
            rules,
            emailRules,
            isValid,
            showAlert,
            alertMessage,
            type,
            practiceTypes,
            btnText,
            creatingAccount,
            showPassword,
            passwordRules,
            price,
            subscriptionOption,
            showPasswordHandler,
            validate,
            formSubmitHandler,
            priceHasChanged,
        };
    },
};
</script>
