<template lang="pug">
    div(v-if="localPrice")
        stripe-element-payment(
            v-if="elementsOptions.clientSecret"
            ref="paymentRef"
            :pk="pk"
            :elements-options="elementsOptions"
            :confirm-params="confirmParams"
            :test-mode="testMode"
        )
        v-btn(@click="pay" color="success")
            | Pay Now
</template>

<script>
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import { onUnmounted, ref, watch } from "@vue/composition-api";
import {
    STRIPE_PUBLISHABLE_KEY,
    STRIPE_PAYMENT_SUCCESS_URL,
    STRIPE_TEST_MODE,
} from "@/common/constants";
import { StripeApiService } from "@/services/stripe-api.service";

const stripeApiService = new StripeApiService();

export default {
    name: "StripePayment",
    components: { StripeElementPayment },
    props: {
        price: {
            type: [Number, null],
            default: null,
        },
    },
    setup(props) {
        const localPrice = ref(props.price);
        const paymentIntentId = ref(null);
        const paymentRef = ref({});
        const pk = ref(STRIPE_PUBLISHABLE_KEY);
        const testMode = ref(STRIPE_TEST_MODE);
        const elementsOptions = ref({
            appearance: {},
        });
        const confirmParams = ref({
            return_url: STRIPE_PAYMENT_SUCCESS_URL,
        });

        const generatePaymentIntent = async () => {
            //TODO: replace with correct price or id
            // the payment price needs to be the smallest unit - so in Cents for AUD
            const paymentIntent = await stripeApiService.generatePaymentIntent(
                3000
            );
            const tempElementsOptions = { ...elementsOptions.value };
            tempElementsOptions.clientSecret = paymentIntent.clientSecret;
            paymentIntentId.value = paymentIntent.id;
            elementsOptions.value = { ...tempElementsOptions };
        };

        const cancelPaymentIntent = async () => {
            // we just let the payment go
            await stripeApiService.cancelPaymentIntent(paymentIntentId.value);
            // console.log('cancelled payment intent', cancelledPaymentIntent)
        };

        const pay = () => {
            if (paymentRef.value.submit) paymentRef.value.submit();
        };

        watch(
            () => props.price,
            (newValue) => {
                localPrice.value = newValue;
            }
        );

        watch(
            localPrice,
            async (newValue) => {
                if (paymentIntentId.value) await cancelPaymentIntent();
                if (newValue) await generatePaymentIntent();
            },
            { immediate: true }
        );

        onUnmounted(async () => {
            // we only destroy the payment if we haven't paid/confirmed
            if (paymentIntentId.value !== null) await cancelPaymentIntent();
        });

        return {
            paymentRef,
            pk,
            elementsOptions,
            confirmParams,
            testMode,
            localPrice,
            pay,
        };
    },
};
</script>
